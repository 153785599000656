const currentDomain = window.location.hostname;
const domainArray = currentDomain.split(".").slice(1).join(".");
const branch = process.env.REACT_APP_ENV;

const neoauth =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_NEOAUTH
    : `https://login.${domainArray}/`;

const appUrl =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_URL
    : `${window.location.protocol}//${window.location.hostname}/`;

const dashboard =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_DASHBOARD
    : `https://dashboard.${domainArray}/`;

export const urlDashboard =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_DASHBOARD
    : `https://dashboard.${domainArray}/`;

const neocoffre =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_NEOCOFFRE
    : `https://neocoffre.${domainArray}/`;

const neoactivite =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_NEOACTIVITE
    : `https://neoactivite.${domainArray}/`;

const neoAffectation =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_NEOAFFECTATION
    : `https://neoaffectation.${domainArray}/`;

const neoscanExpert =
  branch === "local" || branch === "beta"
    ? process.env.REACT_APP_NEOSCANEXPERT
    : `https://neoscan-expert.${domainArray}/`;

const Settings = {
  appUrl,
  fullLoginUrl: "",
  authorizeUrl: `${neoauth}o/authorize/`,
  revokeToken: `${neoauth}o/revoke_token/`,
  clientId: process.env.REACT_APP_API_CLIENT_ID,
  logoutRedirect: `${dashboard}`,
  neoactivite: process.env.REACT_APP_API_NEOACTIVITE,
  neoactiviteWS: process.env.REACT_APP_API_NEOACTIVITE_WS,
  neoscanExpert: process.env.REACT_APP_NEOSCANEXPERT,
};
Settings.fullLoginUrl = `${Settings.authorizeUrl}?response_type=token&client_id=${
  Settings.clientId
}&scope=read&redirect_uri=${Settings.appUrl}${window.location.pathname.substring(
  1,
)}${encodeURIComponent(window.location.search)}`;

export default Settings;

export const APP_URL = {
  neoActiviteUrl: `${neoactivite}`,
  dashboard: `${dashboard}`,
  neocoffre: `${neocoffre}`,
  neoScanExpertUrl: `${neoscanExpert}`,
  neoAffectation: `${neoAffectation}`,
};

export const TreeTableRow = 10;
export const DATE_FORMAT_DISPLAY = "DD/MM/YYYY";
export const DATE_FORMAT_YEAR_MONTH_DISPLAY = "MM/YYYY";
export const DATE_FORMAT_API = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm";
export const LIMIT_PAGINATED = 20;
export const LIMIT_ALL_ITEMS = 100;
